import { Component, Inject, Renderer2 } from '@angular/core';
import { IntroductionComponent } from '../../components/introduction/introduction.component';
import { environment } from '../../../environments/environment';
import { CmsService } from '../../services/cms.service';
import { TranslateService } from '@ngx-translate/core';
import { DiscoverSectionComponent } from "../../components/discover-section/discover-section.component";
import { ServicesSectionComponent } from "../../components/services-section/services-section.component";
import { ReviewSectionComponent } from "../../components/review-section/review-section.component";
import { CalculatorSectionComponent } from "../../components/calculator-section/calculator-section.component";
import { LocationComponent } from "../../components/location/location.component";
import { BlogsComponent } from "../../components/blogs/blogs.component";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [IntroductionComponent, DiscoverSectionComponent, ServicesSectionComponent, ReviewSectionComponent, CalculatorSectionComponent, LocationComponent, BlogsComponent]
})
export class HomeComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  content: any;
  introduction: any;
  aboutUS: any;
  services: any;
  review: any;
  calculator: any;

  constructor(@Inject(DOCUMENT) private document: Document, private cmsService: CmsService, private translate: TranslateService, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
      this.selectedLanguage = res.lang;
      this.getCmsContent();
      // }
    })
  }

  getCmsContent() {
    let populateString = `?populate=deep&locale=${this.selectedLanguage}`;
    this.cmsService.singlePageContent('home-page', populateString).subscribe((res: any) => {
      this.content = res;
      this.introduction = res.introduction;
      this.aboutUS = res.discover;
      this.services = res.serviceSection;
      this.review = res.reviewSection;
      this.calculator = res.calculatorSection;

      // this.cmsService.updateTags(res?.seo);
      // this.cmsService.updateOgTags(res?.seoOg);
      // this.cmsService.updateTwitterTags(res?.seoTwitter);
      this.cmsService.updateGoogleVerification(res?.seoGoogle);
      this.loadGoogleAnalytics(res?.seoGoogle?.googleAnalyticsId);

      this.cmsService.updateMetaAndSocialTags(res.pluginSeo[0]);

    });
  }

  private loadGoogleAnalytics(googleAnalyticsId: string): void {
    const script = this.renderer.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    script.async = true;
    this.renderer.appendChild(this.document.head, script);

    const inlineScript = this.renderer.createElement('script');
    const scriptContent = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${googleAnalyticsId}',{
        'anonymize_ip':true,
        'cookie_flags': 'SameSite=None;Secure'
      });
    `;
    inlineScript.text = scriptContent;
    this.renderer.appendChild(this.document.head, inlineScript);
  }
}
