import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title, Meta } from '@angular/platform-browser';
import { BehaviorSubject, map } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CmsService {
  header = {
    'authorization': `bearer ${environment.API_TOKEN}`
  }

  constructor(private http: HttpClient, private meta: Meta, private titleService: Title) { }

  singlePageContent(page: string, populate?: string) {
    let population = populate || '';
    return this.http.get(`${environment.CMS_URL}/api/${page}${population}`).pipe(
      map(this.mapResponseToAttributes)
    )
  }

  collectionTypesContent(page: string, populate?: string) {
    let population = populate || ''
    return this.http.get(`${environment.CMS_URL}/api/${page}${population}`).pipe(
      map(this.mapResponseCollectionTypesToAttributes)
    )
  }

  collectionService(page: string, populate?: string) {
    let population = populate || ''
    return this.http.get(`${environment.CMS_URL}/api/${page}${population}`)
  }

  mapResponseToAttributes(res: any) {
    return res.data.attributes
  }

  mapResponseCollectionTypesToAttributes(res: any) {
    return res.data.map((x: any) => {
      x.attributes.id = x.id;
      return x.attributes;
    });
  }

  writePopulateFunction(...cmsComponents: string[]) {
    let string = '?'
    cmsComponents.forEach(element => {
      string += `populate[${element}][populate]=*&`
    });
    string += 'populate=*';
    return string;
  }


  //is used to fetch menu data from header to use on footer
  private allMenuItemsSubject = new BehaviorSubject<any[]>([]);
  allMenuItems = this.allMenuItemsSubject.asObservable();


  setAllMenuItems(menuItems: any[]) {
    this.allMenuItemsSubject.next(menuItems);
  }

  public updateTags(tag: any) {
    this.meta.updateTag({ name: 'title', content: tag?.title ? tag?.title : 'neo title' });
    this.meta.updateTag({ name: 'description', content: tag?.description ? tag?.description : 'neo description' });
    this.meta.updateTag({ name: 'keywords', content: tag?.keywords ? tag?.keywords : 'neo keywords' });
  }

  public updateOgTags(tag: any) {
    const image = tag?.image?.data?.attributes?.url ? tag?.image?.data?.attributes?.url : '/uploads/Logo_2_7da5f25bc4.png';
    const alt = tag?.image?.data?.attributes?.alternativeText ? tag?.image?.data?.attributes?.alternativeText : 'neo image';

    this.meta.updateTag({ property: 'og:title', content: tag?.title ? tag?.title : 'neo facebook' });
    this.meta.updateTag({ property: 'og:description', content: tag?.description ? tag?.description : 'neo facebook' });
    this.meta.updateTag({ property: 'og:url', content: tag?.keywords ? tag?.url : 'https://m.facebook.com/100057328106144/about/?referrer=services_landing_page' });
    this.meta.updateTag({ property: 'og:image', content: environment.CMS_URL + image });
  }

  public updateTwitterTags(tag: any) {
    const image = tag?.image?.data?.attributes?.url ? tag?.image?.data?.attributes?.url : '/uploads/Logo_2_7da5f25bc4.png';
    const alt = tag?.image?.data?.attributes?.alternativeText ? tag?.image?.data?.attributes?.alternativeText : 'neo twitter image';

    this.meta.updateTag({ name: 'twitter:title', content: tag?.title ? tag?.title : 'neo twitter' });
    this.meta.updateTag({ name: 'twitter:description', content: tag?.description ? tag?.description : 'neo twitter description' });
    this.meta.updateTag({ name: 'twitter:url', content: tag?.keywords ? tag?.url : 'https://twitter.com' });
    this.meta.updateTag({ name: 'twitter:image', content: environment.CMS_URL + image });
    this.meta.updateTag({ name: 'twitter:image:alt', content: alt });
    this.meta.updateTag({ name: 'twitter:card', content: tag?.card ? tag?.card : 'summary' });
  }

  public updateGoogleVerification(tag: any) {
    this.meta.updateTag({ name: 'google-site-verification', content: tag?.verificationToken ? tag?.verificationToken : 'verification_token' });
  }

  public updateMetaAndSocialTags(tag: any) {
    // this.titleService.setTitle(tag?.metaTitle);

    this.meta.updateTag({ name: 'description', content: tag?.metaDescription || '' });
    this.meta.updateTag({ name: 'keywords', content: tag?.keywords || '' });
    this.meta.updateTag({ name: 'robots', content: tag?.metaRobots || '' });
    this.meta.updateTag({ name: 'viewport', content: tag?.metaViewport || '' });
    this.meta.updateTag({ name: 'canonical', content: tag?.canonicalURL || '' });

    tag.metaSocial.forEach((social: any) => {
      console.log(`Updating ${social.socialNetwork} tags`);
      this.meta.updateTag({ name: `og:${social.socialNetwork.toLowerCase()}:title`, content: social.title });
      this.meta.updateTag({ name: `og:${social.socialNetwork.toLowerCase()}:description`, content: social.description });
      if (social.image?.data) {
        this.meta.updateTag({ name: `og:${social.socialNetwork.toLowerCase()}:image`, content: social.image.data.url });
      }
    });

    if (tag.structuredData) {
      this.addStructuredData(tag.structuredData);
    }
  }

  private addStructuredData(data: any) {
    const scriptTag = document.createElement('script');
    scriptTag.type = 'application/ld+json';
    scriptTag.text = JSON.stringify(data);
    const existingScript = document.querySelector('script[type="application/ld+json"]');
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    document.head.appendChild(scriptTag);
  }

}
