@if(content && content.showSection){
<div class="content" [style.--bgColor]="content?.serviceButton.bgColor">
    <div class="sub-content border-radius" [style.--subBgColor]="content?.bgColor">
        <div class="image">
            <img class="border-radius" src="{{cmsUrl + content?.serviceImage.data.attributes?.url}}" alt="{{content?.serviceImage.data.attributes?.alternativeText}}">
        </div>

        <div class="right-section">
            <div class="font-calibre-120 number">
                {{content?.number}}
            </div>
            <div class="bottom-right">

                    <div class="title mb-25 font-univia-pro-30 text-align-left">{{content?.title}}</div>

                <div class=" description font-calibre-18 mb-25">

                    {{content?.description}}
                </div>
                <app-button class="mb-25" [content]="content.serviceButton" [hideButton]="false"></app-button>
            </div>
        </div>
    </div>
</div>

}