import { Component, OnInit, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-insurance-expert',
  standalone: true,
  imports: [CommonModule, ButtonComponent],
  templateUrl: './insurance-expert.component.html',
  styleUrl: './insurance-expert.component.scss'
})
export class InsuranceExpertComponent implements OnInit {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;

  @Input('content') content: any;
  bgImage: any;
  title: string = '';
  button: any = '';

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      this.selectedLanguage = res.lang;
    })

    this.title = this.content.title;
    this.button = this.content.button;
    this.bgImage = this.cmsUrl + this.content?.bgImage?.data?.attributes.url;
  }
}
