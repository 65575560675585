<div class="intro">
  <div
    class="banner"
    style="--background: url({{ cmsUrl + fcDietikon?.image.url }}) no-repeat"
  >
    <div class="icon-title">
      <div class="icon">
        <img src="{{ cmsUrl + fcDietikon?.icon.url }}" alt="FC_Dietikon_icon" />
      </div>
      <div class="title">
        {{ fcDietikon?.title[selectedLanguage] }}
      </div>
    </div>
  </div>
</div>
<div class="welcome-section">
  {{ fcDietikon?.welcomeText[selectedLanguage] }}
</div>
<div class="horizontal-line"></div>
<div class="contact-forms-section">
  <div class="before-contact-form-text">
    {{ fcDietikon?.beforeContactFormText[selectedLanguage] }}
  </div>
  <div class="contact-forms">
    <div class="forms-box">
      <div class="title">
        {{ fcDietikon?.contactFormContent.title[selectedLanguage] }}
      </div>
      <div class="contact-form contact-form-parent">
        <div class="contact-form-parent-description">
          {{ fcDietikon?.contactFormContent.description[selectedLanguage] }}
        </div>
        <form [formGroup]="contactForm">
          <div class="contact-form-inputs">
            <div class="full-name">
              <input
                type="text"
                class="fc-dietikon-input"
                formControlName="fullName"
                placeholder="{{ 'FC_DIETIKON.INPUTS.FULL_NAME' | translate }}"
                [class.fc-dietikon-input-invalid]="triedToSubmit && !contactForm?.controls['fullName']?.valid"
              />
            </div>
            <div class="addresses">
              <div class="address">
                <input
                  type="text"
                  class="fc-dietikon-input"
                  formControlName="address"
                  placeholder="{{ 'FC_DIETIKON.INPUTS.ADDRESS' | translate }}"
                [class.fc-dietikon-input-invalid]="triedToSubmit && !contactForm?.controls['address']?.valid"
                />
              </div>
              <div class="city">
                <input
                  type="text"
                  class="fc-dietikon-input"
                  formControlName="city"
                  placeholder="{{ 'FC_DIETIKON.INPUTS.CITY' | translate }}"
                  [class.fc-dietikon-input-invalid]="triedToSubmit && !contactForm?.controls['city']?.valid"
                  readonly
                />
              </div>
              <div class="zip">
                <input
                  class="fc-dietikon-input"
                  [formControl]="regionFC"
                  name="zip"
                  placeholder="{{ 'FC_DIETIKON.INPUTS.ZIP' | translate }}"
                [class.fc-dietikon-input-invalid]="triedToSubmit && !contactForm?.controls['regionFC']?.valid"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete
                  #auto="matAutocomplete"
                  (optionSelected)="selectedRegion($event)"
                  [displayWith]="displayRegionFn.bind(this)"
                >
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                  >
                    {{ option.PLZ }} - {{ option.Ort }} ({{ option.Gemeinde }})
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
            <div class="phone-number">
              <input
                type="text"
                class="fc-dietikon-input"
                formControlName="phoneNumber"
                placeholder="{{
                  'FC_DIETIKON.INPUTS.PHONE_NUMBER' | translate
                }}"
                [class.fc-dietikon-input-invalid]="triedToSubmit && !contactForm?.controls['phoneNumber']?.valid"
              />
            </div>
            <div class="birthday">
              <input
                type="text"
                class="fc-dietikon-input"
                formControlName="birthday"
                placeholder="{{ 'FC_DIETIKON.INPUTS.BIRTHDAY' | translate }}"
                [class.fc-dietikon-input-invalid]="triedToSubmit && !contactForm?.controls['birthday']?.valid"
                onfocus="(this.type='date')"
              />
            </div>
            <div class="accept-agb">
              <input type="checkbox" name="accept-agb" id="accept-agb" #acceptAgb (input)="checkAGB(acceptAgb)"/><label
                for="accept-agb"
                (click)="onLabelClick($event)"
                [innerHTML]="agbContent"
                ></label
              >
            </div>
          </div>
        </form>
      </div>
      <div class="contact-form-child">
        <div class="contact-form-child-description">
          {{
            fcDietikon?.contactFormContent.childFormDescription[
              selectedLanguage
            ]
          }}
        </div>
        <form [formGroup]="childForm">
          <div class="contact-form-inputs">
            <div class="full-name">
              <input
                type="text"
                class="fc-dietikon-input"
                formControlName="fullName"
                placeholder="{{ 'FC_DIETIKON.INPUTS.FULL_NAME' | translate }}"
                [class.fc-dietikon-input-invalid]="triedToSubmit && !childForm?.controls['fullName']?.valid"
              />
            </div>
            <div class="addresses">
              <div class="address">
                <input
                  type="text"
                  class="fc-dietikon-input"
                  formControlName="address"
                  placeholder="{{ 'FC_DIETIKON.INPUTS.ADDRESS' | translate }}"
                  [class.fc-dietikon-input-invalid]="triedToSubmit && !childForm?.controls['address']?.valid"
                />
              </div>
              <div class="city">
                <input
                  type="text"
                  class="fc-dietikon-input"
                  formControlName="city"
                  placeholder="{{ 'FC_DIETIKON.INPUTS.CITY' | translate }}"
                  [class.fc-dietikon-input-invalid]="triedToSubmit && !childForm?.controls['city']?.valid"
                  readonly
                />
              </div>
              <div class="zip">
                <input
                  class="fc-dietikon-input"
                  [formControl]="regionFCChild"
                  name="zip"
                  placeholder="{{ 'FC_DIETIKON.INPUTS.ZIP' | translate }}"
                  [class.fc-dietikon-input-invalid]="triedToSubmit && !childForm?.controls['regionFCChild']?.valid"
                  [matAutocomplete]="auto1"
                />
                <mat-autocomplete
                  #auto1="matAutocomplete"
                  (optionSelected)="selectedRegionChild($event)"
                  [displayWith]="displayRegionFn.bind(this)"
                >
                  <mat-option
                    *ngFor="let option of filteredOptionsChild | async"
                    [value]="option"
                  >
                    {{ option.PLZ }} - {{ option.Ort }} ({{ option.Gemeinde }})
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
            <div class="phone-number">
              <input
                type="text"
                class="fc-dietikon-input"
                formControlName="phoneNumber"
                placeholder="{{
                  'FC_DIETIKON.INPUTS.PHONE_NUMBER' | translate
                }}"
                [class.fc-dietikon-input-invalid]="triedToSubmit && !childForm?.controls['phoneNumber']?.valid"
              />
            </div>
            <div class="birthday">
              <input
                type="text"
                class="fc-dietikon-input"
                formControlName="birthday"
                placeholder="{{ 'FC_DIETIKON.INPUTS.BIRTHDAY' | translate }}"
                [class.fc-dietikon-input-invalid]="triedToSubmit && !childForm?.controls['birthday']?.valid"
                onfocus="(this.type='date')"
              />
            </div>
          </div>
        </form>
      </div>
      @if (triedToSubmit) {
        <div class="invalid">
          @if(!readAGB) {
            <div class="invalid-message check-AGB">
              {{"FC_DIETIKON.MESSAGES.CHECK_AGB" | translate}}
            </div>
          }
          @if(!contactForm.valid && !childForm.valid){
            <div class="invalid-message invalid-fields">
              {{"FC_DIETIKON.MESSAGES.INVALID_FIELDS" | translate }}
            </div>
          }
        </div>
      }
      <div class="button-forms">
        <button class="forms-btn" (click)="submitContactInfo()">
          {{ "COMMON.BUTTON.REGISTER"  | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="after-contact-form-text">
    {{ fcDietikon?.afterContactFormText[selectedLanguage] }}
  </div>
</div>
<div class="curve-footer">
  <img src="assets/images/fc-dietikon-swirl-footer-swirl.png" alt="">
</div>
