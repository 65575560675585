@if(content){

<div class="footer-content" [style.--bgColor]="content?.bgColor" [style.--text-color]="content?.textColor">

    <div class=" first-section">
        <div class="right-section">
            <img class="icon" src="{{ cmsUrl + content?.logoSvg.data.attributes.url }}"
                alt="{{ content?.logoSvg.data.attributes.alternativeText }}" />

            <div class="phone-button">
                <div class="phone-number font-calibre-20">
                    {{content?.textPhone}}: <a href="{{'tel:'+content?.phoneNumber}}">{{content?.phoneNumber}}</a>
                </div>
                <div class="button text-uppercase">
                    <app-button [content]="content?.button"></app-button>
                </div>
            </div>
        </div>

        <!-- menu desktop -->
        <div class="left-section full-width">
            @for(menuItems of allMenuItems; track menuItems; let i=$index){
            <div class="menu-items font-univia-pro-20">
                <div class="menu-item">
                    @if(!menuItems.subMenuItem?.length){
                    <span (click)="handleMenuItemClick(i, menuItems)">
                        {{ menuItems?.title }}
                    </span>
                    }
                    @else {
                    <span>
                        {{ menuItems?.title }}
                    </span>
                    }
                </div>
                <div class="sub-menu-items font-calibre-18">
                    @if(menuItems.subMenuItem?.length){
                    @for (subMenu of menuItems?.subMenuItem; track subMenu) {
                    <div class="sub-menu-item"
                        (click)="handleSubMenuItemClick(menuItems?.redirectLink, subMenu?.fragment, subMenu?.redirectLink)">
                        {{subMenu.title}}
                    </div>
                    }
                    }
                </div>
            </div>

            }
        </div>

        <!-- menu mobile -->
        <div class="left-section smaller-width">
            @for(menuItems of allMenuItems; let i = $index; track menuItems){
            <div class="menu-items font-univia-pro-20" (click)="handleMenuItemClick(i, menuItems)">
                <div class="menu-item">
                    <span>
                        {{ menuItems?.title }}
                    </span>
                    @if(menuItems.subMenuItem?.length){
                    <div>

                        <img [src]="openMenuItemIndex === i ? 'assets/images/up.svg' : 'assets/images/down.svg'"
                            width="18" height="10" alt="Arrow icon">
                    </div>
                    }
                </div>
                @if(menuItems.subMenuItem?.length && openMenuItemIndex === i){
                <div class="sub-menu-items font-calibre-18">
                    @for (subMenu of menuItems?.subMenuItem; track subMenu) {
                    <div class="sub-menu-item"
                        (click)="handleSubMenuItemClick(menuItems?.redirectLink, subMenu?.fragment, subMenu?.redirectLink)">
                        {{subMenu.title}}
                    </div>
                    }
                </div>
                }
            </div>

            }
        </div>

    </div>
    <div class="second-section">
        <div class="powered-by">
            <span class="powered-text">{{content?.poveredByText}}</span>
            <img class="powered-icon" src="{{ cmsUrl + content?.poweredByIcon.data.attributes.url }}"
                alt="{{ content?.poweredByIcon.data.attributes.alternativeText }}" />

        </div>
        <div class="copy-right">
            {{content?.copyRightText}}
        </div>
        <div class="media-section">
            <div class="media-icons">
                @for(media of content?.mediaIcons; track media){

                <div class="media-icon">
                    <img src="{{cmsUrl + media.icon.data.attributes.url}}"
                        alt="{{media.icon.data.attributes.alternativeText}}" (click)="open(media?.redirectLink)">
                </div>
                }
            </div>

            <div class="impressum">
                @for (item of content?.textAndLink; track item; let last = $last) {
                <div (click)="footerRedirectTo(item.redirectLink, item.redirectFragment)">
                    <span class="title">
                        {{item.title}}
                    </span>
                    @if(!last){

                    <span class="separate-symbol">|</span>
                    }
                </div>
                }

            </div>
        </div>
    </div>
</div>
}