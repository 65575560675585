@if(content){
<div class="card-content">
    <div>
        <div class="label" [style.--bgColor]="content?.attributes.bgColorTitle">{{content?.attributes.title}}</div>
        <div class="description">{{content?.attributes.description}}</div>
    </div>

    <img class="image" src="{{cmsUrl + content?.attributes.image.data.attributes.url}}"
        alt="{{content?.attributes.image.data.attributes.alternativeText}}">
</div>
}