import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-introduction',
    standalone: true,
    templateUrl: './introduction.component.html',
    styleUrl: './introduction.component.scss',
    imports: []
})
export class IntroductionComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;

  @Input('useCenter') useCenter!: boolean;
  @Input('content') content: any;

  constructor(private translate: TranslateService, private router: Router) {
   }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
        this.selectedLanguage = res.lang;
      // }
    })

  }

  redirectTo(link: string) {
    this.router.navigate([link]);
  }


}
