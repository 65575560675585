@if(content){
<div class="mt-5 pt-5">
    <app-title-section [content]="header" [useCenter]="true"></app-title-section>
    <p class="m-md-5 mt-3 px-3 px-md-5 text-center size-18 col-blue">{{description}}</p>
</div>
<div class="row mx-1 mb-5 pb-5">
    @for(card of cardContent; track card){
    <div class="col-12 col-sm-6 col-md-{{cardContent.length % 4 == 0 ? '3' : '4'}} mt-4">
        <div class="card h-100 mx-1" [style.border-color]="card?.bgColor">
            <div class="card-body text-start">
                <div class="d-flex justify-content-start align-items-center px-2 py-4">
                    <img class="tick" [src]="cmsUrl + card?.image" />
                    <h5 class="ml-3 h-franchise">{{card?.title}}</h5>
                </div>
                <p class="px-2 pb-4 size-18 col-grey">{{card?.description}}</p>
            </div>
        </div>
    </div>
    }
</div>
}