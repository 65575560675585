import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-calculator-button',
  standalone: true,
  imports: [],
  templateUrl: './calculator-button.component.html',
  styleUrl: './calculator-button.component.scss'
})
export class CalculatorButtonComponent {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  @Input('content') content: any;
  iconColor: string = ''

  constructor(private translate: TranslateService, private router: Router, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.selectedLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe(res => {
      // if (this.selectedLanguage !== res.lang) {
      this.selectedLanguage = res.lang;
      // }
    })
    this.iconColor = this.content?.buttonTextColor;

  }

  redirectTo(link: string, fragment: string) {
    let url = `${link}#${fragment}`;
    if (fragment) {
      window.location.href = url;

    }
    else if (link) {

      window.location.href = link;
    }
    else {
      return;
    }
  }

  sanitizeSVG(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.cmsUrl + url}`);
  }
  onHover(): void {
    this.iconColor = 'white';
  }

  onMouseOut(): void {
    this.iconColor = this.content?.buttonTextColor;
  }
}