import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TitleSectionComponent } from '../title-section/title-section.component';
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { CardModalComponent } from '../card-modal/card-modal.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-kvg-card',
  standalone: true,
  imports: [CommonModule, TitleSectionComponent],
  templateUrl: './kvg-card.component.html',
  styleUrl: './kvg-card.component.scss'
})
export class KvgCardComponent implements OnInit {
  selectedLanguage!: string;
  cmsUrl = environment.CMS_URL;
  @Input('content') content: any;
  cardContent: any;
  cardBg: string='';
  hoveredUsage: string = '';

  constructor(private translate: TranslateService, private router: Router, private sanitizer: DomSanitizer, private modalService: NgbModal) {
  }

  ngOnInit(): void {
      this.cardContent = this.content?.cardContent.map((card: any) => {
        return {
          id: card?.id,
          title: card?.title,
          description: card?.description,
          descriptionModal: card?.descriptionModal,
          image: card?.cardIcon?.data?.attributes?.url,
          cardBg: card?.bgColor 
        }
      })
      }

  redirectTo(link: string) {
    this.router.navigate([link]);
  }
  
  sanitizeSVG(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(`${this.cmsUrl + url}`);
  }

  onHover(id: number,cardBg: string): void {
    const card = this.cardContent.find((card: any) => card?.id == id);
    this.cardBg = cardBg;
  }
  
  onMouseOut(): void {
    this.cardBg = 'white';
  }

  openModal(selectedCard: number, backgroundColor: any) {
    document.documentElement.style.setProperty('--bg-color', backgroundColor);
    const modalRef = this.modalService.open(CardModalComponent, {size: 'lg', centered: true});
    modalRef.componentInstance.cardContentDetails = this.cardContent.find((card: any) => card?.id == selectedCard);
  }
}
