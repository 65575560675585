<!-- <div id="history" [style.--bgColor]="content?.bgColor"> -->
<!-- <div id="history" [ngClass]="{'section-pt': !bgColor}"> -->
    <div id="history" [style.--bgColor]="content?.bgColor" [ngClass]="{'section-pt': !content?.bgColor}">

    <div *ngIf="screenWidth < 1150" class="history-mobile-title">
        <div class="history-title-content">
            <div class="history-tagline">
                <div class="history-tagline-text">
                    {{content?.SectionTitle}}</div>
            </div>
            <div class="history-title">
                {{content?.Title}}</div>
        </div>
    </div>

    <div class="history-section" [ngClass]="bgColor ? 'colored-bg' : 'content-top'">
        <div class="history-image-container col-12 col-md-4">
            <img class="history-image" [alt]="content?.image?.data?.attributes?.alternativeText"
                [src]="cmsUrl + content?.image?.data?.attributes?.url">
        </div>
        <!-- <div class="history-content col-12 col-md-8" [class.margin-bottom]="marginBottom"> -->
        <div class="history-content col-12 col-md-8" [ngClass]="{'no-bg-margin history-content-pt': !bgColor}" [class.margin-bottom]="marginBottom">
            <div class="history-title-content" *ngIf="screenWidth >= 1150">
                <div class="history-tagline">
                    <div class="history-tagline-text">
                        {{content?.SectionTitle}}</div>
                </div>
                <div class="history-title">
                    {{content?.Title}}</div>
            </div>
            <div class="history-paragraph-content">
                @if(content?.FirstParagraph){
                    <div class="history-paragraph-text">
                        {{content?.FirstParagraph}} </div>
                }
                @if(content?.SecondParagraph){
                    <div class="history-paragraph-text">
                        {{content?.SecondParagraph}}</div>
                }
                @if(content?.ThirdParagraph){
                    <div class="history-paragraph-text">
                        {{content?.ThirdParagraph}}</div>
                }
            </div>
        </div>
    </div>
</div>