@if(content) {
<div class="introduction intro-image" style="--backgroundUrl: url({{cmsUrl + content?.image.data.attributes.url}})">
  <div class="gap-8 inline-flex flex-col text-content" [class.align-items-center]="useCenter"
    [class.display-flex-when-center]="useCenter">
    <!-- <div class="gap-4 flex flex-col  content-padding"> -->
    <div class="flex flex-col  content-margin introduction-title-container content-padding">

      <div class="intro-title mb-0 font-univia-pro-70 text-align-left">
        {{content?.title}}
      </div>
      <div class="intro-title mb-0 font-univia-pro-70 text-align-left">
        {{content?.title2}}
      </div>
      @if(content?.description){
      <div class="subtitle-content mb-0 font-calibre-18 width-50 pt-4 pt-md-0" [class.margin-auto]="useCenter">
        {{content?.description}}
      </div>
      }
    </div>
  </div>
</div>
}