<div class="team-section">
    <div class="team-section-header">
        <div class="team-section-title-content">
            <div class="team-section-tagline">
                <div class="team-section-text">
                    {{content?.TeamSectionHeader?.SectionTitle}}</div>
            </div>
            <div class="team-section-title">
                {{content?.TeamSectionHeader?.Title}}</div>
        </div>
        <div class="team-navigation-buttons">
            <div class="team-navigation-buttons">
                <div class="team-navigation-icon" class="button-prev">
                    <div class="team-navigation-icon prev-next" [class.index-zero]="activeIndex == 0"
                        [style.--btnBgColor]="'#5bc9e9'">
                        <img src="../../../assets/icons/Left.png" alt="Left arrow" class="arrow">
                    </div>
                </div>

                <div class="team-navigation-icon" class="button-next">
                    <div class="team-navigation-icon prev-next"
                        [class.index-zero]="activeIndex == content?.TeamMemberCard?.length-1"
                        [style.--btnBgColor]="'#5bc9e9'">
                        <img src="../../../assets/icons/Right.png" alt="Right arrow" class="arrow">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="w-100 h-70">
        <swiper-container swiperElement [config]="config" init="false" class="swiper">
            <swiper-slide *ngFor="let member of content?.TeamMemberCard">
                <div class="team-member-card">
                    <div class="image-container">
                        <img class="team-member-image" [src]="cmsUrl + member?.Image?.data?.attributes?.url"
                            [alt]="member?.image?.data?.attributes?.alternativeText" />
                    </div>
                    <div class="team-member-content">
                        <div class="team-member-title">{{ member?.NameLastname }}</div>
                        <div class="team-section-tagline">
                            <div class="team-member-position-text">{{ member?.Position }}</div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
        </swiper-container>
    </div>
</div>