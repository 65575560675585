@if(!hideButton && content?.showSection){
<div class="button-component font-univia-pro-regular-14" [style.--btnBgColor]="content?.bgColor"
  [style.--color]="content?.buttonTextColor">
  <button class="component-btn" (click)="redirectTo(content?.redirectLink, content?.redirectFragment)">
    {{content?.buttonText | uppercase}}
    @if(content?.btnSvg && content?.btnSvg?.data && content?.btnSvg?.data?.attributes){
      <img class="icon" src="{{ cmsUrl + content?.btnSvg.data.attributes?.url }}" alt="{{ content?.btnSvg?.data?.attributes?.alternativeText }}" />
    }
  </button>
</div>
}